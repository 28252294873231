<nav class="navbar navbar-expand-lg  navbar-dark bg-success">
  <a class="navbar-brand" href="#">Farm <span>Fresh</span> Market</a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarTogglerDemo02">
    <ul class="navbar-nav ml-auto mt-2 mt-lg-0">
      <li class="nav-item">
        <a class="nav-link" routerLink="/home" routerLinkActive="active">Home</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="https://www.farmfreshmarket.net.au/onlineorder.php">Online Order</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/delivery" routerLinkActive="active">Delivery</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/specialty" routerLinkActive="active">Specialty</a>
      </li>
      <!--
      <li class="nav-item">
        <a class="nav-link" routerLink="/glutenfree" routerLinkActive="active">Gluten Free</a>
      </li>
      -->
      <li class="nav-item">
        <a class="nav-link" routerLink="/contact" routerLinkActive="active">Contact Us</a>
      </li>
			<!-- Dropdown -->
    </ul>
  </div>
</nav>

<router-outlet></router-outlet>

<!-- footer -->
<div class="footer">
     <footer>
        <div class="container">
            <div class="row ">
                <div class="col-md-5 text-center text-md-left ">

                    <div class="py-0">
                        <h3 class="my-4 text-white"><span class="mx-2 font-italic text-warning ">Farm Fresh Market</span></h3>

                        <p class="footer-links font-weight-bold">
													<span class="font-weight-bold" style="color:#aaa;font-style:italic">Opening Hours:</span><br>
													7:30am - 5:30pm Mon-Fri<br>
													7:30am - 12:30pm Sat
												</p>
                        <p class="footer-links font-weight-bold">
                            <a class="text-white" href="#">Home</a>
                            |
                            <a class="text-white" href="#">Delivery</a>
                            |
                            <a class="text-white" href="https://order.farmfreshmarket.net.au/onlineorder.php">Order</a>
                            |
                            <a class="text-white" href="#">Contact</a>
                        </p>
                    </div>
                </div>

                <div class="col-md-3 text-white text-center text-md-left ">
                    <div class="py-2 my-4">
                        <div>
                            <p class="text-white"> <i class="fa fa-map-marker mx-2 "></i>
																185 Adelaide Rd<br>
                            		Murray Bridge SA 5253</p>
                        </div>

                        <div>
                            <p><i class="fa fa-phone  mx-2 "></i>(08) 8531 2888</p>
                        </div>
                        <div>
                          <p><i class="fa fa-envelope  mx-2 "></i><a href="mailto:farmfreshmb@hotmail.com">farmfreshmb@hotmail.com</a></p>
                        </div>
                    </div>
                </div>

                <div class="col-md-4 text-white my-4 text-center text-md-left ">
                    <span class=" font-weight-bold ">Farm Fresh Market</span>
                    <p class="text-warning my-2" >Murray Bridge<br/>
                        Fruiterers, Greengrocers &amp; Wholesalers.</p>
        <div class="py-2">
          <a href="https://www.facebook.com/FarmFreshMarket.MurrayBridge1?ref=br_tf" target="new">
							<i class="fa fa-facebook fa-2x text-primary mx-3"></i></a>
							<a class="text-white" href="https://www.facebook.com/FarmFreshMarket.MurrayBridge1?ref=br_tf" target="new">Like us on Facebook</a>
        </div>
      </div>
    </div>
			<div class="row">
				<div class="col-md-6 copy-left">
      		<p>&copy;2021 Farm Fresh Market.</p>
				</div>
				<div class="col-md-6 copy-right">
      		<p>Website design by <a href="https://webineering.com.au">The Webineering Workshop</a></p>
				</div>
			</div>
  </div>
</footer>
</div>
     <!-- end of footer -->
